import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/homepage/homepage/src/layouts/pages.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Software­produkt­management`}</h1>
    <p>{`Als Produktmanager oder Product Owner entwickle ich seit 2012 Software-Produkte. Ich bin die Schnittstelle zwischen Nutzern, Entwicklern, Vertrieb und Marketing. Mein Grundsatz sowohl bei Neu- als auch bei Weiterentwicklungen ist, mir anhand von `}<a parentName="p" {...{
        "href": "https://longform.asmartbear.com/slc/"
      }}><strong parentName="a">{`SLC`}</strong></a>{`, gesprochen `}<em parentName="p">{`Slick`}</em>{`, folgende Fragen zu stellen:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Simple`}</strong>{`: Was brauchen Nutzer nicht?`}</li>
      <li parentName="ul"><strong parentName="li">{`Lovable`}</strong>{`: Wie fühlen sich Nutzer wohl?`}</li>
      <li parentName="ul"><strong parentName="li">{`Complete`}</strong>{`: Was ist das primäre Ziel der Nutzer?`}</li>
    </ul>
    <img src="portrait.jpg" alt="Jörg Zeipelt" style={{
      "width": "100%",
      "maxWidth": "512px"
    }} />
    <h1>{`Mission`}</h1>
    <p>{`Ich mache Produkte, die die Menschen lieben, weil sie ihnen einen großen Nutzen bringen, schön sind und einfach zu bedienen. Meine langjährige Erfahrung mit schlanken Geschäftsprozessen, mein Expertenwissen in agilen Prozessen und mein tiefes Verständnis von Webtechnologie ermöglichen mir dies.`}</p>
    <h1>{`Beruflicher Werdegang`}</h1>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p"><a parentName="strong" {...{
              "href": "https://www.flexcavo.de/"
            }}>{`Flexcavo`}</a>{` von 2021 bis jetzt`}</strong>{`:`}<br parentName="p"></br>{`
`}{`Flexcavo ist eine SaaS-Flottenmanagement-Lösung für größere Bauunternehmen. Anfangs ging es für mich darum, den Administrationsaufwand für neue und bestehende Nutzer zu vereinfachen. Derzeit geht es vor allem darum, die komplexe Einsatz- und Transportplanung so abzurunden, dass wir alle real auftretenden Fälle bei unseren Kunden voll abbilden können. Gleichzeitig optimieren wir alle Prozesse so, dass wirklich jeder Nutzer, von Bauhofleiter, über Bauleiter und Polier unsere App problemlos und selbsterklärend nutzen kann. Außerdem integrieren wir die Planungsfunktionen mehr und mehr in `}<a parentName="p" {...{
            "href": "https://www.trackunit.com/"
          }}>{`Trackunit`}</a>{`, dem Mutterunternehmen von Flexcavo seit Anfang 2023.  `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p"><a parentName="strong" {...{
              "href": "https://www.z-lab.com/"
            }}>{`Zeppelin Lab`}</a>{` von 2017 bis 2021`}</strong>{`:`}<br parentName="p"></br>{`
`}{`Hier war ich praktisch an allen Produkten beteiligt: `}<a parentName="p" {...{
            "href": "https://www.klickrent.de/"
          }}>{`klickrent`}</a>{`, maschinator, `}<a parentName="p" {...{
            "href": "https://zamics.de/"
          }}>{`zamics`}</a>{`, `}<a parentName="p" {...{
            "href": "https://akii.app/"
          }}>{`akii`}</a>{`, und vor allem `}<a parentName="p" {...{
            "href": "https://klickcheck.com/"
          }}>{`klickcheck`}</a>{`, das ich von den ersten Ideen über erste Pilotkunden und bis zu größerer Verbreitung mit aufgebaut habe.  `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p"><a parentName="strong" {...{
              "href": "https://www.home24.de/"
            }}>{`Home24`}</a>{` von 2013 bis 2016`}</strong>{` `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p"><a parentName="strong" {...{
              "href": "https://exb.de/"
            }}>{`ExB`}</a>{` von 2012 bis 2013`}</strong></p>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      